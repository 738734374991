import React from 'react';
import HoverImage from './HoverImage';


type Props = {
    projectName: string
}

const Recent = ({projectName} : Props) => {

    return (
        <div className='flex flex-col'>
            <hr className="h-px my-4 bg-gray-800 border-0.5"></hr>
                <div className="text-4xl text-nearBlack leading-normal space-x-4">
                    <HoverImage imageUrl='./img/sevenStories.jpg' imageAlt='a gallery with bird hanging in it and a street crossing leading t-shirts with a distorted, AI-generated pattern hanging from a ceiling' text='Seven Stories of Mellonopolis' />
                    <svg className="inline" height="40" width="40">
                        <circle cx="20" cy="20" r="18" stroke="black" stroke-width="3" fill="none" />
                    </svg>
                    <p className="font-bold inline font-StratosLights">Speculative Design</p> 
                </div>
             <hr className="h-px my-4 bg-gray-800 border-0.5"></hr>
             <div className="text-4xl text-nearBlack leading-normal space-x-4">
                 <HoverImage imageUrl='./img/threeTrans_thumb.jpg' imageAlt='The words climate, biodiversity, and human life on a gridded background' text='Three Transitions' />
                    <svg className="inline" height="40" width="40">
                        <circle cx="20" cy="20" r="18" stroke="black" stroke-width="3" fill="none" />
                    </svg>
                    <p className="font-bold inline font-StratosLights">Climate Communications</p> 
                </div>
             <hr className="h-px my-4 bg-gray-800 border-0.5"></hr>
             <div className="text-4xl text-nearBlack leading-normal space-x-4">
                 <HoverImage imageUrl='./img/epn_thumb.jpg' imageAlt='A screenshot of a website where a map of the British Isles is displayed with a cluster of red and green clouds mapped on top' text='Energy Proverb Net' />
                    <svg className="inline" height="40" width="40">
                        <circle cx="20" cy="20" r="18" stroke="black" stroke-width="3" fill="none" />
                    </svg>
                    <p className="font-bold inline font-StratosLights">Speculative Design</p> 
                </div>
             <hr className="h-px my-4 bg-gray-800 border-0.5"></hr>
             <div className="text-4xl text-nearBlack leading-normal space-x-4">
                <HoverImage imageUrl='./img/minimalMaps_thumb.jpg' imageAlt='a dark blue map showing a winding river in white with many tributaries and smaller streams diverging from it' text='Minimal Maps' />
                    <svg className="inline" height="40" width="40">
                        <circle cx="20" cy="20" r="18" stroke="black" stroke-width="3" fill="none" />
                    </svg>
                    <p className="font-bold inline font-StratosLights">Data Visualisation</p> 
                </div>
             <hr className="h-px my-4 bg-gray-800 border-0.5"></hr>
             <div className="text-4xl text-nearBlack leading-normal space-x-4">
                <HoverImage imageUrl='./img/MigrantSpecies_thumb.png' imageAlt='a website that is bright yellow with the phrase tell us where your family comes from in red' text='Migrant Species' />
                    <svg className="inline" height="40" width="40">
                        <circle cx="20" cy="20" r="18" stroke="black" stroke-width="3" fill="none" />
                    </svg>
                    <p className="font-bold inline font-StratosLights">Design for Activism</p> 
                </div>
             <hr className="h-px my-4 bg-gray-800 border-0.5"></hr>
             <div className="text-4xl text-nearBlack leading-normal space-x-4">
                <HoverImage imageUrl='./img/atNight_thumb.jpg' imageAlt='a zoomed in photograph of a screen with a series of abstract letters on it' text='At Night' />
                    <svg className="inline" height="40" width="40">
                        <circle cx="20" cy="20" r="18" stroke="black" stroke-width="3" fill="none" />
                    </svg>
                    <p className="font-bold inline font-StratosLights">Climate Communications</p> 
                </div>
             <hr className="h-px my-4 bg-gray-800 border-0.5"></hr>

         <div className='text-xl text-nearBlack font-HalyardDisplay font-normal leading-normal content-end uppercase tracking-wide pt-32'>
                recent / under NDA
         </div>
         <hr className="h-px my-4 bg-gray-800 border-1"></hr>
         <div className=' text-2xl text-nearBlack font-Stratos leading-relaxed max-w-3xl'>
            <p>Guidelines and principles of XR for a major tech company</p>
            <p>The future of cities for an engineering company</p>
            <p>Digital product design for a global travel company</p>
            <p>The future of freelance for a major government agency</p>
            <p>A mobile app for a FinTech startup</p>
            <p>Tools for climate awareness for a major tech company</p>
            <p>Innovation design for a major tech company</p>
        </div>


        </div>
        )
}

export default Recent;